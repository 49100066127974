import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Cards } from "../card";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TimeLine } from "../timeline";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";
import axios from "axios";
import { PageEmpty } from "../pageEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(3),
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
  },
  flex: {
    display: "flex",
  },
}));
export const MainPanel = (props) => {
  const { selectedTask, logState, isLoading, onUserActionPerformed } = props;
  const classes = useStyles();
  const alertMessage = React.useContext(AlertContext);
  const [statesAction, setStatesAction] = React.useState([]);
  const db = atob(sessionStorage.getItem("ProjectDbname"));

  // React.useEffect(() => {
  //   if (selectedTask[0]?.field6) {
  //     getApplicableAction(selectedTask[0]?.field6);
  //   }

  //   //eslint-disable-next-line
  // }, [selectedTask[0]?.field6]);

  // const getApplicableAction = async (ticketId) => {
  //   try {
  //     let params = {
  //       db_name: `${db}`,
  //       entity: "QDMATPtransactionlog,queuescenariostatemapping",
  //       filter: {
  //         QDMATPtransactionlog: `QDMATPtransactionlog.activestatus==true && QDMATPtransactionlog.ticketId=='${ticketId}'`,
  //         queuescenariostatemapping:
  //           "queuescenariostatemapping.activestatus==true && queuescenariostatemapping.queueid==QDMATPtransactionlog.queueIds && queuescenariostatemapping.scenarioid IN QDMATPtransactionlog.payload.scenarioId[*] LET docstates=(FOR docstates IN TO_ARRAY(queuescenariostatemapping.states) FILTER docstates._id IN QDMATPtransactionlog.payload.statesid[*] return docstates.action[*])",
  //       },
  //       return_fields: `{'docStatesActions':docstates[0]}`,
  //     };
  //     let config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: params,
  //     };

  //     await axios(config)
  //       .then((res) => {
  //         if (res.data.Code === 201) {
  //           let data = res.data.result[0];
  //           setStatesAction(data?.docStatesActions);
  //         } else {
  //           alertMessage?.setSnack({
  //             ...alertMessage,
  //             open: true,
  //             severity: AlertProps.severity.error,
  //             msg: "Something Went Wrong",
  //             vertical: AlertProps.vertical.top,
  //             horizontal: AlertProps.horizontal.center,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {}
  // };

  const a = new Date(selectedTask[0]?.field9 * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const performUserAction = async (Action) => {
    debugger;
    if (Action) {
      try {
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_PERFORMUSERACTION_API}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            ticketId: `${selectedTask[0]?.subtitle2}`,
            userAction: Action,
          },
        };
        await axios(config).then((res) => {
          if (!res.data.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: `You've marked report as "${Action}"`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            onUserActionPerformed(selectedTask[0]?.subtitle2);
            // setState({});
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res.data.errorMessage}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        });
      } catch (error) {}
    }
  };

  return (
    <div className={classes.root}>
      {logState.length !== 0 ? (
        <>
          {selectedTask.length === 0 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography variant="body1" color="InactiveCaptionText">
                {`"Please Select a task from the left panel to view the configuration"`}
              </Typography>
            </div>
          ) : (
            <>
              <Cards
                type={2}
                action={statesAction}
                field1={selectedTask[0]?.field1}
                field2={selectedTask[0]?.field2}
                field9={selectedTask[0]?.field9}
                field4={selectedTask[0]?.field4}
                field5={selectedTask[0]?.field5}
                field6={selectedTask[0]?.field6}
                field7={selectedTask[0]?.field7?.states}
                performUserAction={performUserAction}
              />

              <div
                style={{
                  height: "calc(100% -  120px)",
                  overflowX: "auto",
                  margin: "8px 0px",
                }}
              >
                <div>
                  <div
                    style={{
                      margin: "8px 0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EventNoteIcon color="info" />
                    <Typography variant="caption" color="InactiveCaptionText">
                      &nbsp;{"Other Details"}
                    </Typography>
                  </div>
                  <Grid container>
                    <Grid
                      lg={6}
                      xs={12}
                      style={{
                        padding: "4px 12px",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                        // width: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          margin: "8px 0px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="InactiveCaptionText"
                        >
                          {"Request raised on: "}
                        </Typography>
                        <Typography variant="caption">
                          {/* &nbsp;{"31 Apr 2022"} */}
                          &nbsp;
                          {`${a.toLocaleDateString(undefined, options)}`}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          margin: "8px 0px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="InactiveCaptionText"
                        >
                          {"Request raised by: "}
                        </Typography>
                        <Typography variant="caption">
                          &nbsp;{selectedTask[0]?.field1 ?? ""}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "8px 0px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="InactiveCaptionText"
                        >
                          {"Request purpose: "}
                        </Typography>
                        <Typography variant="caption">
                          &nbsp;{selectedTask[0]?.field5 ?? ""}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Divider style={{ margin: "16px 0px" }} />
                <div>
                  <div
                    style={{
                      margin: "4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccessTimeIcon color="info" />
                    <Typography variant="caption" color="InactiveCaptionText">
                      &nbsp;{"Timeline"}
                    </Typography>
                  </div>
                  <TimeLine
                    scenarioState={props.scenarioState}
                    currantState={selectedTask[0]?.field7}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          {isLoading ? <CircularProgress /> : <PageEmpty />}
        </div>
      )}
    </div>
  );
};
