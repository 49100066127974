import React from "react";
import { withAllContexts, withSideBars } from "./../../HOCs";
import TaskViewMain from "./taskViewMain";

class TaskViewerConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
       <TaskViewMain/>
      </>
    );
  }
}

export default withSideBars(withAllContexts(TaskViewerConfig));
