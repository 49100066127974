import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { TopNavbarParent } from "../../components";
import TaskView from "./taskView";

function TaskViewMain() {
  const [viewState, setViewState] = useState([]);
  const db = atob(sessionStorage.getItem("ProjectDbname"));
  const [queueDetails, setQueueDetails] = useState([]);
  
  useEffect(() => {
      let params = {
        db_name: `${process.env.REACT_APP_DB_Name}`,
        entity: "projectvstools",
        filter: `projectvstools.projectid=='${process.env.REACT_APP_PROJECT_ID}' && projectvstools.activestatus==true`,
        return_fields: `MERGE(UNSET(projectvstools,'createdby','createddate','updatedby','updatedate'),{clientname:first(for client in clients filter client._id==projectvstools.clientid return client.clientname)},{projectname:first(for project in projects filter project._id==projectvstools.projectid return project.projectname )})`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
       axios(config)
        .then((res) => {
          // window.location.reload(true);
          console.log("SSS")
          let data = res.data.result[0];
          sessionStorage.setItem("ProjectDbname", btoa(data.dbname));
          console.log("called second")
          this.setState({
            ...this.state,
            call: true,
            // isRender: true,
          });
          
        })
        .catch((err) => {
          console.log(err);
          // alert("Something Went Wrong");
        });
    
    taskViewLoad();
  },[db]);
  const updatestate = (value) => {
    console.log("value", value);
    setQueueDetails(value);
  };

  const taskViewLoad = async () => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "queue",
        filter: "queue.activestatus==true",
        sort: "queue.queuename ASC",
        return_fields: `{'queuename':queue.queuename,'queue_id':queue._id,'inputschema':queue.inputschema}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;
            setViewState(data);
            console.log("de", data);
            setQueueDetails(data[0]);
            localStorage.setItem("queue_id", data[0]?.queue_id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  return (
    <div>
      <TopNavbarParent
        taskView={viewState}
        updatestate={updatestate}
        queueDetails={queueDetails}
      />
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          {queueDetails !== null && <TaskView queueDetails={queueDetails} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default TaskViewMain;
