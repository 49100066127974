import React from "react";
import { Modal, Typography } from "@mui/material";
import { Label, StyledButton } from "../../styledcomponent";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@mui/styles";
import { Box, styled } from "@mui/system";
import { ModalQueueList } from "./modalQueueList";

const MyBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  left: "50%",
  maxWidth: "250px",
  zIndex: "3",
  transform: "translate(-50%)",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
};

export const QueueModal = (props) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MyBox>
        <StyledButton onClick={() => handleOpen()}>
          <Typography variant="body1">{"Queue"}</Typography>
          <Label
            color={theme.palette.info.main}
          >{`${props.queues.length}`}</Label>
          <ExpandLessIcon fontSize="small" />
        </StyledButton>
      </MyBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalQueueList queues={props.queues} handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};
