import React from "react";
import { NetworkCall } from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { ProjectInfoContext } from "./contexts";
import axios from "axios";
class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      call: false,
      isRender: false,
    };
  }

  componentDidMount() {
    // window.location.reload(false);
    let urlParams = new URLSearchParams(window.location.search);
    let isMicroFrontEnd = urlParams?.get("ismicrofrontend") ?? false;
    sessionStorage.setItem("isMicroFrontEnd", isMicroFrontEnd);
    // sessionStorage.setItem("ProjectDbname", null);
    this.updateAll();
    
  }

  updateAll = async () => {
    await Promise.all([
      this.checkForLatestBuild(),
      this.refreshAPI(),
      this.getProjectDetails(),
    ]);
  };

  getProjectDetails = async () => {
    try {

      let params = {
        db_name: `${process.env.REACT_APP_DB_Name}`,
        entity: "projectvstools",
        filter: `projectvstools.projectid=='${process.env.REACT_APP_PROJECT_ID}' && projectvstools.activestatus==true`,
        return_fields: `MERGE(UNSET(projectvstools,'createdby','createddate','updatedby','updatedate'),{clientname:first(for client in clients filter client._id==projectvstools.clientid return client.clientname)},{projectname:first(for project in projects filter project._id==projectvstools.projectid return project.projectname )})`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          // window.location.reload(true);
         
          let data = res.data.result[0];
          sessionStorage.setItem("ProjectDbname", btoa(data.dbname));
          this.setState({
            ...this.state,
            call: true,
            // isRender: true,
          });
          
        })
        .catch((err) => {
          console.log(err);
          // alert("Something Went Wrong");
        });
    } catch (error) {
      console.log(error);
    }
  };

  refreshAPI = async () => {
    // console.log(urlParams?.get("token"));
    // window.onbeforeunload = function (e) {
    //   window.onunload = function () {
    //     window.localStorage.isMySessionActive = "false";
    //   };
    //   return undefined;
    // };
    // window.onload = function () {
    //   window.localStorage.isMySessionActive = "true";
    // };
  };

  checkForLatestBuild = () => {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  render() {
    let { isRender } = this.state;

    return (
      <ProjectInfoContext.Provider value={{ ...this.state }}>
        {isRender ? <></> : this.props.children}
      </ProjectInfoContext.Provider>
    );
  }
}

export default AppAuth;
