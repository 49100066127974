import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { Cards } from "../card";
import { PageEmpty } from "../pageEmpty";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    borderRight: "1px solid #e0e0e0",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
  },
  flex: {
    display: "flex",
  },
}));

export const SidePanel = (props) => {
  const {
    logState = null,
    onCardClicked = () => false,
    isLoading = false,
    queueId,
    scenarioId,
    each,
    stateid,
    getTaskViewerConifg = () => false,
  } = props;
  const classes = useStyles();
  const [tasklist, setTask] = React.useState();

  React.useEffect(() => {
    setTask(logState);
  }, [logState]);

  const [search, setSearch] = React.useState();
  const [checked, setChecked] = React.useState(false);
  // const [FilterBy, setFilterBy] = React.useState([
  //   { isChecked: false, label: "Sort By A-Z", title: "title" },
  //   { isChecked: false, label: "Sort By Task-Id", title: "subtitle2" },
  // ]);
  const [ticketchecked, setticketchecked] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleChange = (e) => {
    let searchInput = e.target.value;
    setSearch(e.target.value);
    const list = logState.filter((l) => {
      // return (
      //   l.title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
      //   l.subtitle2.toLowerCase().indexOf(searchInput.toLowerCase() !== -1)
      // );
      return (
        l.field1.toLowerCase().includes(searchInput.toLowerCase()) ||
        l.field5.toLowerCase().includes(searchInput.toLowerCase()) ||
        l.field2.toLowerCase().includes(searchInput.toLowerCase()) ||
        l.field4.toLowerCase().includes(searchInput.toLowerCase()) ||
        l.field6.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    setTask(list);

    // console.log(list);
  };

  const onSortClick = () => {
    debugger;
    if (!checked) {
      setChecked(true);
      setticketchecked(false);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.field1.toUpperCase();
        const nameB = b.field1.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setTask(sortlist);
      handleClose();
    } else {
      setChecked(false);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
    }
  };

  const onSortticketClick = () => {
    debugger;
    if (!ticketchecked) {
      setticketchecked(true);
      setChecked(false);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.field6.toUpperCase();
        const nameB = b.field6.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setTask(sortlist);
      handleClose();
    } else {
      setticketchecked(false);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
    }
  };

  // const onFilterBy = (isChecked, value, id) => {
  //   debugger;
  //   const filterChecked = FilterBy.map((l, index) =>
  //     l.isChecked === true
  //       ? Object.assign(l, { isChecked: false })
  //       : index === id
  //       ? Object.assign(l, { isChecked: !isChecked })
  //       : l
  //   );
  //   setFilterBy(filterChecked);
  //   if (!isChecked) {
  //     // setticketchecked(true);
  //     const sortlist = tasklist.sort(function (a, b,value) {
  //       const nameA = a.title.toUpperCase();
  //       const nameB = b.title.toUpperCase();
  //       if (nameA < nameB) {
  //         return -1;
  //       }
  //       if (nameA > nameB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setTask(sortlist);
  //   } else {
  //     // setticketchecked(false);
  //     getTaskViewerConifg(queueId, scenarioId, each, stateid);
  //   }
  // };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.Header}>
          <Typography variant="body1" noWrap>
            {"Action Item"}
          </Typography>
          <div className={classes.flex}>
            <IconButton
              size="small"
              style={{ borderRadius: "8px", border: "1px solid #e0e0e0" }}
              onClick={(e) => handleClick(e)}
            >
              <FilterListIcon />
            </IconButton>
            {/* <IconButton size="small">
              <MoreVertIcon />
            </IconButton> */}
          </div>
        </div>
        <Grid item xs={12} sx={{ padding: "8px" }}>
          <TextField
            id="outlined-name"
            // label="Enter State Name"
            name="Search"
            value={search}
            onChange={(e) => onHandleChange(e)}
            placeholder="Search"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // error={state?.errorStatesName}
            // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}
          />
        </Grid>
        {tasklist?.length !== 0 ? (
          <div style={{ height: "calc(100% - 120px)", overflowX: "auto" }}>
            {tasklist?.map((log, index) => (
              <Cards
                type={1}
                index={index}
                data={log}
                field1={log?.field1}
                field2={log?.field2}
                field9={log?.field9}
                field4={log?.field4}
                field5={log?.field5}
                field6={log?.field6}
                field7={log?.field7?.states}
                onCardClicked={onCardClicked}
              />
            ))}
          </div>
        ) : (
          <Grid
            container
            xs={12}
            style={{
              padding: "8px",
              height: "calc(100% - 120px)",
              display: "grid",
              placeItems: "center",
            }}
          >
            {isLoading ? <CircularProgress /> : <PageEmpty />}
          </Grid>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List dense>
            <ListItem dense disableGutters disablePadding>
              <ListItemButton role={undefined} onClick={() => onSortClick()}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox
                    edge="start"
                    checked={checked}
                    disableRipple
                    inputProps={{ "aria-labelledby": "labelId" }}
                  />
                </ListItemIcon>
                <ListItemText id={"labelId"} primary="Sort By A-Z" />
              </ListItemButton>
            </ListItem>
            <ListItem dense disableGutters disablePadding>
              <ListItemButton
                role={undefined}
                onClick={() => onSortticketClick()}
              >
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox
                    edge="start"
                    checked={ticketchecked}
                    disableRipple
                    inputProps={{ "aria-labelledby": "labelId" }}
                  />
                </ListItemIcon>
                <ListItemText id={"labelId"} primary="Sort By Task-ID" />
              </ListItemButton>
            </ListItem>
            {/* {FilterBy.map((l, index) => (
              <ListItem key={index} dense>
                <ListItemButton
                  role={undefined}
                  onClick={() => onFilterBy(l.isChecked, l.title, index)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={l.isChecked}
                      disableRipple
                      inputProps={{ "aria-labelledby": "labelId" }}
                    />
                  </ListItemIcon>
                  <ListItemText id={"labelId"} primary={l.label} />
                </ListItemButton>
              </ListItem>
            ))} */}
          </List>
        </Popover>
      </div>
    </>
  );
};
