import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  updateEdge,
  addEdge,
  Handle,
  applyNodeChanges,
  applyEdgeChanges,
} from "react-flow-renderer";
import axios from "axios";
import {
  Typography,
  Divider,
  List,
  ListItem,
  Grid,
  Button,
} from "@material-ui/core";
import { Tooltip } from "@mui/material";

import LinkIcon from "@mui/icons-material/Link";

import { Paper } from "@material-ui/core";

import "./index.css";
import { withAllContexts } from "../../HOCs";
import { AlertProps } from "../../utils";
import _ from "lodash";

function TaskView(props) {
  const { queueDetails } = props;
  const db = atob(sessionStorage.getItem("ProjectDbname"));
  const [nodeinfo, setnodeinfo] = useState([]);

  useEffect(() => {
    debugger;
    console.log("props", props);
    if (queueDetails && queueDetails?.inputschema?.length > 0) {
      let inputschema = queueDetails.inputschema[0].inputschema;
      mainViewLoad(queueDetails.inputschema[0].inputschema);

      console.log(JSON.parse(inputschema));
    }
  }, [queueDetails]);
  const mainViewLoad = async (schema) => {
    debugger;
    try {
      let params = {
        db_name:`${db}`,
        entity: "Taskviewer_config",
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_URL_GETMETA_SAMPLEJSON}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          debugger;
          let data = res.data[0].doc;
          delete data._id;
          delete data.queueid;
          let fields = Object.keys(data);

          let createobj = [];
          console.log(
            "filter",
            fields.filter((v, i) => i < 9)
          );
          let field = fields
            .filter((v, i) => i < 9)
            .map((l, i) => {
              let obj = {
                id: `A-${l}`,

                data: { label: `${l}`, value: "source" },
                type: "customnode",
                position: { x: 20, y: 20 + i * 50 },
                parentNode: "A",
                extent: "parent",
                draggable: false,
                style: {
                  width: "260px",
                  color: "#000",
                },
              };
              return obj;
            });
          console.log(JSON.stringify(createobj));
          const getList = (schema, name) => {
            debugger;
            let lists = schema.required.map((list) => {
              let children = undefined;
              if (
                schema.properties[list].type === "object" &&
                schema.properties[list].required.length > 0
              ) {
                let p = `${name}  ${list}`;
                children = getList(schema.properties[list], p);
                if (Array.isArray(children)) {
                  let li = children.map((key) => key);
                  return li.flat();
                } else {
                  return children;
                }
              } else if (
                schema.properties[list].type === "string" ||
                schema.properties[list].type === "integer" ||
                schema.properties[list].type === "boolean"
              ) {
                return `${name}.${list}`;
              } else if (schema.properties[list].type === "array") {
                return `${name}.${list}`;
              } else {
                return `${name}.${list}`;
              }
            });
            return lists.flat();
          };
          let validSchema = JSON.parse(schema);
          let fs = getList(validSchema[0], "");
          let bgroup = fs.map((s, i) => {
            let obj = {
              id: `B-${s.startsWith(".") ? s.substring(1).trim() : s.trim()}`,
              data: {
                label: `${s.startsWith(".") ? s.substring(1).trim() : s.trim()
                  }`,
                value: "target",
              },
              type: "customnode",
              position: { x: 20, y: 20 + i * 50 },
              parentNode: "B",
              extent: "parent",
              draggable: false,
              style: {
                width: "260px",
                color: "#000",
              },
            };
            return obj;
          });
          let updatelist = [
            {
              id: "A",
              type: "group",
              data: { label: `task viewer config` },
              position: { x: 5, y: 5 },
              // sourcePosition: "top",
              draggable: false,
              // style: {
              //   backgroundColor: "rgba(255, 255, 255, 0.99)",
              //   height: "100%",
              //   zIndex: -2,
              // },
            },
            {
              id: "B",
              type: "group",
              data: { label: `input schema` },
              position: { x: 460, y: 5 },
              // targetPosition: "top",
              // style: {
              //   height: "100vmax",
              //   backgroundColor: "rgba(255, 255, 255, 0.99)",

              //   zIndex: -2,
              // },
              draggable: false,
            },
            ...field,
            ...bgroup,
          ];
          setnodeinfo(updatelist);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  return (
    <>
      <Flower node={nodeinfo} props={props} />
    </>
  );
}
export default withAllContexts(TaskView);
export const Flower = (props) => {
  const { node } = props;
  const [nodes, setNodes] = useNodesState(node);
  const [edges, setEdges] = useEdgesState([]);
  const panOnDrag = false;
  const [listObj, setlistObj] = useState([]);
  const [bool, setBool] = useState(false);
  const db = atob(sessionStorage.getItem("ProjectDbname"));
  const [mappedDetails, setMappedDetails] = useState({});
  const [keystate, setKeyState] = useState("");
  const [taskViewData, setTaskViewData] = useState(false);
  const [queueData, setQueueData] = useState(false);
  const [buttonUpdate, setButtonUpdate] = useState(false);
  const queueinfo = localStorage.getItem("queue_id");
  const map = useRef([]);
  useEffect(() => {
    console.log("1st", node);
    setNodes(node);
    console.log("2nd", edges);
    edges.length > 0 && wholelist();
  }, [node, edges]);
  const constructEdge = (data) => {
    debugger;
    let simpleEdge = [];
    const edgeField = {
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      field6: "",
      field7: "",
      field8: "",
      field9: "",
    };
    Object.keys(edgeField).map((e) => (edgeField[e] = data[e]));
    let schemaData = Object.values(edgeField);
    const rightFields = [
      "field1",
      "field2",
      "field3",
      "field4",
      "field5",
      "field6",
      "field7",
      "field8",
      "field9",
    ];
    let finaledge = {};
    schemaData.forEach((p, i) => {
      if (p !== undefined) {
        //  c.push(i)
        finaledge[rightFields[i]] = p;
      }
    });

    Object.entries(finaledge).map((e) => {
      console.log("wwww", e);
      simpleEdge.push({
        id: `${e}`,
        source: `A-${e[0]}`,
        sourceHandle: e[0],
        target: `B-${e[1]}`,
        targetHandle: e[1],
      });
    });
    console.log("ssss", simpleEdge);
    setEdges(simpleEdge);
  };
  useEffect(() => {
    map.current = [];
    var readData;
    readData = {
      db_name: `${db}`,
      entity: "Taskviewer_config",
      filter: `Taskviewer_config.queueid=='${queueinfo}'`,
      return_fields: "Taskviewer_config",
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
      header: { "Content-Type": "application/json" },
      data: readData,
    };
    console.log("read db info", readData);
    axios(config)
      .then((res) => {
        debugger;
        console.log(
          "information",
          res.data.result,
          res.data.result[0]?._key,
          res.data.result.length !== 0
        );
        if (res.data.result.length !== 0) {
          debugger;
          setTaskViewData(true);
          setQueueData(true);
          setKeyState(res.data.result[0]?._key);

          constructEdge(res.data.result[0]);
        } else {
          setTaskViewData(false);
          setEdges([]);
          setQueueData(false);
          props.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "No data  ",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [db, queueinfo]);

  const onNodesChange = useCallback(
    (changes) => {
      return setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => {
      console.log("changes", changes);
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const wholelist = useCallback(() => {
    var objpro = {};

    let listObje = edges.map((a, i) => {
      let key = a.sourceHandle;
      let value = a.targetHandle;
      let obj = { [`${key}`]: value };
      objpro[key] = value;
      return obj;
    });
    console.log("record", listObje, edges, objpro);
    setlistObj(listObje);
    setMappedDetails(objpro);
    map.current = objpro;
  }, [edges]);

  const CustomInput = (data) => (
    <>
      <div id={"content"} className="textColor">
        {data.label}
      </div>
      <Handle
        type="target"
        position="left"
        onConnect={(params) => console.log("handle onConnect", params)}
      />
    </>
  );
  const CustomNode = ({ data }) => (
    <>
      <div
        id={data.label}
        style={{ textTransform: "capitalize", color: "#000" }}
      >
        {data.label}
      </div>
      {data.value === "target" ? (
        <Handle id={`${data.label}`} type="target" position="left" />
      ) : (
        <Handle id={`${data.label}`} type="source" position="right" />
      )}
    </>
  );
  const nodeTypes = React.useMemo(
    () => ({ custominput: CustomInput, customnode: CustomNode }),
    []
  );
  const onEdgeUpdate = (oldEdge, newConnection) => {
    debugger;
    console.log(
      " new connections",
      newConnection.sourceHandle,
      mappedDetails,
      Object.keys(mappedDetails),
      Object.keys(mappedDetails).indexOf(newConnection.sourceHandle) === -1
    );
    console.log("new connection", listObj);
    console.log(" new map data", map.current);
    console.log("new old data", oldEdge, newConnection);
    let filterofkeys = Object.keys(map.current).filter(
      (v) => v === newConnection.sourceHandle
    );
    let filterofvalues = Object.values(map.current).filter(
      (v) => v === newConnection.targetHandle
    );
    if (filterofkeys.length === 0 && filterofvalues.length === 1) {
      setEdges((els) => updateEdge(oldEdge, newConnection, els));

      setButtonUpdate(true);
    } else if (filterofkeys.length === 1 && filterofvalues.length === 0) {
      setEdges((els) => updateEdge(oldEdge, newConnection, els));

      setButtonUpdate(true);
    } else {
    }
  };
  const onConnect = useCallback(
    (connection) => {
      debugger;
      console.log(
        "connection",
        connection.sourceHandle,
        mappedDetails,
        Object.keys(mappedDetails),
        Object.keys(mappedDetails).indexOf(connection.sourceHandle) === -1
      );
      console.log("connections", listObj);
      console.log("hojae", map.current);
      let filterofkeys = Object.keys(map.current).filter(
        (v) => v === connection.sourceHandle
      );
      let filterofvalues = Object.values(map.current).filter(
        (v) => v === connection.targetHandle
      );

      if (filterofkeys.length === 0 && filterofvalues.length === 0) {
        setEdges((eds) => addEdge(connection, eds));

        setTaskViewData(true);
      } else {
      }
    },

    [setEdges]
  );

  const onConnectStart = (event, { nodeId, handleType }) => {
    setBool(true);
    console.log("on connect start", { nodeId, handleType });
  };
  const onConnectStop = (data) => console.log("on connect stop", data);
  const onConnectEnd = (data) => console.log("on connect end", data);
  const handlemouse = (event) => {
    if (bool) {
      //console.log("event", event);
      if (event.clientY > window.innerHeight - 83) {
        document.getElementById("procoder").scrollBy(0, 10);
      }
      if (event.clientY < 210) {
        document.getElementById("procoder").scrollBy(0, -10);
      }
      if (event.clientY > window.innerHeight - 83) {
        document.getElementById("masscoder").scrollBy(0, 10);
      }
      if (event.clientY < 210) {
        document.getElementById("masscoder").scrollBy(0, -10);
      }
    }
  };
  console.log("Dwwd", taskViewData);

  //UPSERT DOC//

  const submit = async (e) => {
    var sendData;
    e.preventDefault();
    var readData;
    var disableButton;
    readData = {
      db_name: `${db}`,
      entity: "Taskviewer_config",
      filter: `Taskviewer_config.queueid=='${localStorage.getItem(
        "queue_id"
      )}'`,
      return_fields: "Taskviewer_config",
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
      header: { "Content-Type": "application/json" },
      data: readData,
    };
    console.log("read db info", readData);
    axios(config)
      .then((res) => {
        let objpro = {};
        let listObj = edges.map((a, i) => {
          let key = a.sourceHandle;
          let value = a.targetHandle;
          let obj = { [`${key}`]: value };
          objpro[key] = value;
          return obj;
        });
       

        var pro = {
          ...objpro,
          queueid: localStorage.getItem("queue_id"),
        };
        if (res.data.result.length !== 0) {
          const edgeField = {
            field1: "",
            field2: "",
            field3: "",
            field4: "",
            field5: "",
            field6: "",
            field7: "",
            field8: "",
            field9: "",
          };
          Object.keys(edgeField).map(
            (e) => (edgeField[e] = res.data.result[0][e])
          );
          let schemaData = Object.values(edgeField);
          const rightFields = [
            "field1",
            "field2",
            "field3",
            "field4",
            "field5",
            "field6",
            "field7",
            "field8",
            "field9",
          ];
          let finaledge = {};
          schemaData.map((p, i) => {
            if (p !== undefined) {
              //  c.push(i)
              return finaledge[rightFields[i]] = p;
            }
            return finaledge;
          });
          disableButton = _.isEqual(finaledge, map.current);
          if (!disableButton) {
            console.log("pro", pro,listObj );
            sendData = {
              db_name:`${db}`,
              entity: "Taskviewer_config",
              metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
              filter: { queueid: localStorage.getItem("queue_id") },
              doc: pro,
            };
            var config = {
              method: "post",
              url: process.env.REACT_APP_ARANGO_URL_UPSERT,
              header: { "Content-Type": "application/json" },
              data: [sendData],
            };
            console.log("Db info", sendData);
            console.log("Awdawd", keystate, taskViewData, queueData);

            console.log("para", config);
            if (Object.keys(listObj).length >= 1) {
              axios(config)
                .then((res) => {
                  console.log("balu", res.data.Result[0].properties.doc._key);

                  setButtonUpdate(false);
                  setKeyState(res?.data?.Result[0]?.properties.doc?._key);
                  props.props.alert.setSnack({
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Data is successfully Updated",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                  });
                })
                .catch((err) => {
                  props.props.alert.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                  });
                  console.error(err);
                });
            } else {
            }
          } else {
            props.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.warning,
              msg: "Data Already Exist",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
          console.log("lodash", disableButton);

          console.log(
            "active ",
            map.current,
            _.isEqual(edgeField, map.current),
            edgeField,
            edges
          );
        } else {
         
          pro = {
            ...objpro,
            queueid: localStorage.getItem("queue_id"),
          };
          console.log("objpro",objpro)
          sendData = {
            db_name: `${db}`,
            entity: "Taskviewer_config",
            metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
            doc: pro,
          };
          var configs = {
            method: "post",
            url: process.env.REACT_APP_ARANGO_URL_UPSERT,
            header: { "Content-Type": "application/json" },
            data: [sendData],
          };
          console.log("Db info", sendData);
          console.log("Awdawd", keystate, taskViewData, queueData);

          console.log("para", configs);
          if (Object.keys(listObj).length >= 1) {
            axios(configs)
              .then((res) => {
                console.log("balu", res.data.Result[0].properties.doc._key);

                setButtonUpdate(false);
               

                setKeyState(res?.data?.Result[0]?.properties.doc?._key);
                props.props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: "Data is successfully inserted",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              })
              .catch((err) => {
                console.error(err);
              });
          }
        }
      })
      .catch((err) => {
        props.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        console.error(err);
      });

    if (Object.keys(listObj).length >= 1 && disableButton !== true) {
      axios(config)
        .then((res) => {
          setKeyState(res?.data?.result[0]?.properties?.doc?._key);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <Grid container md={12} style={{ padding: "8px" }}>
        {/* {console.log("sad",taskViewData)} */}
        <Grid
          item
          md={8}
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            marginTop: "6px",
          }}
        >
          <ReactFlow
            id="procoder"
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            className="validationflow"
            onEdgeUpdate={onEdgeUpdate}
            onConnectStart={onConnectStart}
            defaultZoom={1}
            maxZoom={0.5}
            minZoom={1}
            zoomOnScroll={false}
            style={{
              overflowY: "scroll",
              color: "black !important",
              // marginLeft:"8px"
            }}
            preventScrolling={false}
            onConnect={onConnect}
            panOnDrag={panOnDrag}
            panOnScrollMode="free"
            nodeTypes={nodeTypes}
            onConnectStop={onConnectStop}
            onConnectEnd={onConnectEnd}
            onMouseMoveCapture={handlemouse}
            //attributionPosition="top-right"
          ></ReactFlow>
        </Grid>
        <Grid item md={4}>
          <Paper
            style={{
              width: "377px",
              height: "100vh",
              // display: "flex",
              // flexDirection:"column",
              // justifyContent:"center",
              textAlign: "left",
              marginLeft: "6px",
              marginTop: "5px",
              alignItems: "center",
              overflowY: "scroll",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                marginLeft: "14px",
                marginTop: "10px",
                color: "#001C3C",
                font: "normal normal 600 16px/20px Poppins",
              }}
            >
              <Typography>Mapping Details</Typography>
              <Divider style={{ marginTop: "8px" }} />
            </div>
            {taskViewData ? (
              <Grid
                item
                container
                justifyContent="center"
                style={{
                  minHeight: "70vh",
                }}
              >
                <Grid container direction="column">
                  <List>
                    {listObj.map((s, i) => (
                      // <div style={{ display: "flex", alignItems: "center" }}>
                      //   <p>{Object.keys(s)}</p>
                      //   <p>{Object.values(s)}</p>
                      // </div>
                      <ListItem key={i} button disableTouchRipple>
                        <Tooltip title={Object.keys} arrow placement="top">
                          <Typography textalign="Center" noWrap>
                            {/* {Object.keys(s).toString().substring(2)} */}
                            {Object.keys(s)}
                            {/* //{console.log("lo", Object.keys(s))} */}
                          </Typography>
                        </Tooltip>
                        <div>
                          <LinkIcon
                            color="primary"
                            sx={{
                              marginTop: "8px",
                            }}
                          />
                        </div>
                        <Tooltip title={Object.values(s)} arrow placement="top">
                          <Typography textalign="Center" noWrap>
                          {Object.values(s)}
                            {/* {Object.values(s)} */}
                          </Typography>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Button
                    //  disabled={disableButton}
                    //disabled={disable}
                    disableTouchRipple
                    variant="contained"
                    color={"primary"}
                    style={{
                      // marginTop: "10px",
                      // marginLeft: "150px",
                      borderRadius: "14px",
                    }}
                    onClick={(e) => submit(e)}
                  >
                    {buttonUpdate ? "Update" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container style={{ justifyContent: "center" }}>
                <Typography variant="h6">No Data Available</Typography>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
