import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TopNavbarParent } from "../components";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    background: "#F2F3F6",
  },
  MicroFrontEnd: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    background: "#F2F3F6",
  },
  topNavbar: {},
}));

const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}

      <div className={classes.topNavbar}>
        <TopNavbarParent />
      </div>

      {/* Content */}
      <div className={classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
