import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { MeetingRoom } from '@mui/icons-material';
import { LocalStorageKeys } from "../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
//import  Divider from "@material-ui/core";
// import MenuIcon from "@mui/icons-material/Menu";

import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,

  // NativeSelect,
  Divider,
} from "@mui/material";
// import { SideNavBar } from '..';
import Popover from "@mui/material/Popover";
// import Button from '@mui/material/Button';
import LogoutIcon from "@mui/icons-material/Logout";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    height: "48px",
  },
  title: {
    display: "block",
    // margin:"50px"
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: "8px",
    marginBottom: "14px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "centre",
    "& li": {
      // fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
    "& li.Mui-selected": {
      color: theme.palette.common.black,
      background: theme.palette.info.light,
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
  },
}));

export const TopNavBar = (props) => {
  const classes = useStyles();
  const { taskView, queueDetails } = props;

  // console.log('props',props)

  const [anchorEl, setAnchorEl] = React.useState(null);

  // React.useEffect(() => {
  //   setAge(props?.defaultScenarios);
  // }, [props?.defaultScenarios]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const handleChange = (value) => {
  //   setAge(value);
  //   props.updateScenaorio(value);
  //   // console.log(value);
  // };

  const handleChangew = (value) => {
    //setAge(event.target.value);
    localStorage.setItem("queue_id", value?.queue_id);
    props.updatestate(value);
    console.log("op", value);
  };

  const handlemouse = (event) => {
    // console.log(
    //   event.clientY <= window.innerHeight - 30,
    //   window.innerHeight - 30,
    //   event.clientY
    // );
    if (event.clientY > window.innerHeight - 30) {
      window.scrollBy(0, 1000);
    }
  };

  return (
    <div className={classes.grow} onMouseMoveCapture={handlemouse}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar sx={{ padding: "0 16px" }}>
          <div className={classes.titleContainer}>
            <Typography
              className={classes.title}
              style={{
                marginBottom: "8px",
              }}
              variant="body1"
              noWrap
            >
              {"Task Viewer Configuration"}
            </Typography>
          </div>
          {/* <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={scenarios}
                      onChange={handleChangew}
                      input={<BootstrapInput />}
                      MenuProps={menuProps}
                    >
                      {taskView?.map((v, index) => {
                        return (
                          <MenuItem key={index} value={v.queue_id}>
                            {v.queuename}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "8px 8px", height: "36px" }}
          />
          <Autocomplete
            id="Printer Model"
            style={{
              width: "250px",
              marginBottom: "20px",
              //marginLeft:"8px"
            }}
            options={taskView ?? []}
            getOptionLabel={(option) => option.queuename || ""}
            value={queueDetails}
            //fullWidth
            defaultValue={queueDetails}
            onChange={(e, value) => handleChangew(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                size="small"
                margin="dense"
                placeholder="Select Queue"
              />
            )}
          />

          <div className={classes.grow} />

          <div className={classes.userProfilecard}>
            <Typography
              className={classes.title}
              style={{
                marginBottom: "12px",
              }}
              variant="body1"
              noWrap
            >
              {/* userName */}
              Admin
            </Typography>
            <Avatar className={classes.profilePic} variant="rounded">
              A
            </Avatar>
          </div>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon sx={{ width: "30px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
