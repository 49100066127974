import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { QueueModal } from "../components";

import { AlertProps } from "../utils";
import { AlertContext, ProjectInfoContext } from "../contexts";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    position: "relative",
  },
  content: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "#ECF0F7",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56
    // },
    //[theme.breakpoints.down('md')]: {
    // paddingLeft: 0
    // ,
    //}
  },
  // topNavbar: {
  //},
  // sideNavbar: {
  // [theme.breakpoints.down('md')]: {
  // display: "none"
  //}
  //}
}));

const withQueueModal = (Component) => (props) => {
  const classes = useStyles({ props });
  const [queues, setQueues] = React.useState([]);
  const alertMessage = React.useContext(AlertContext);
  const projectContext = React.useContext(ProjectInfoContext);

  // console.log(db);
  // React.useEffect(() => {
  //   if (projectContext.call) {
  //     getCreatedQueue(atob(sessionStorage.getItem("ProjectDbname")));
  //   }
  //   // eslint-disable-next-line
  // }, [projectContext.call]);

  // const getCreatedQueue = async (db) => {
  //   try {
  //     let params = {
  //       db_name: `${db}`,
  //       entity: "queue",
  //       filter: `queue.activestatus==true`,
  //       sort: "queue.queuename ASC",
  //       return_fields: "queue",
  //     };
  //     let config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: params,
  //     };
  //     await axios(config).then((res) => {
  //       if (res.data.Code === 201) {
  //         setQueues(res.data.result);
  //       } else {
  //         alertMessage?.setSnack({
  //           ...alertMessage,
  //           open: true,
  //           severity: AlertProps.severity.error,
  //           msg: "Something Went Wrong",
  //           vertical: AlertProps.vertical.top,
  //           horizontal: AlertProps.horizontal.center,
  //         });
  //       }
  //       // if(data)
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <React.Fragment>
      {/* Your nav bars here */}
      <div className={classes.root}>
        {/* Content */}
        <div className={classes.content}>
          <Component queues={queues} {...props}>
            {props.children}
          </Component>
          <QueueModal queues={queues} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withQueueModal;
