/**
 * @author Gayathri Murugesan
 * @email gayathri.murugesan@ainqa.com
 * @create date 2022-04-01
 * @modify date 2022-04-01
 * @desc This File has all the navigation routes and it's rendering components will bes here.
 */

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
import { TaskViewerConfig  } from "./../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Home Routes */}
        <Route exact path={Routes.taskViewer}>
       < TaskViewerConfig/> 
        </Route>
        
      </Switch>
    </Router>
  );
};

export default RouterApp;
